/* === Primary === */
$brand-maroon: #500000;
$brand-white: #ffffff;

// For hover effects
$brand-maroon-darker: scale-color($brand-maroon, $lightness: -25%);

/* === Secondary === */
$brand-blue: #003c71;
$brand-green: #5b6236;
$brand-brown: #744f28;
$brand-tan: #998542;
$brand-gray: #332c2c;
$brand-light-gray: #707373;
$brand-sand: #d6d3c4;

// For hover effects
$brand-gray-darker: scale-color($brand-gray, $lightness: -25%);
$brand-sand-lighter: scale-color($brand-sand, $lightness: 25%);

/* === Accent === */
$brand-yellow: #fce300;
$brand-red: #e4002b;
