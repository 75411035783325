.SiteFooter {
  background: $brand-gray;
  padding: 1.25rem 0;

  @include breakpoint(large) {
    height: 80px;
  }

  &__logo {
    text-align: center;

    @include breakpoint(large) {
      text-align: left;
    }
  }

  &__menu {
    text-align: center;

    @include breakpoint(large) {
      text-align: right;
    }

    > li > a {
      color: $brand-white;
      font-weight: 600;
      line-height: rem-calc(40px);

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }
}
