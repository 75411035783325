.main-content {
  @include breakpoint(large) {
    // Full window height minus height of header and footer
    min-height: calc(100vh - 210px);
  }
}

.MainPage-Hero {
  width: 100%;
  padding: rem-calc(200px 0);
  background: url("/assets/img/research/Perla.png");
  background-position: center top;
  background-size: cover;

  &__featured-quote {
    padding: 0;
    border-left: 0;

    &__content {
      font-family: $brand-georgia;
      font-size: rem-calc(24px);
      color: $brand-gray;
      margin-bottom: rem-calc(10px);
    }

    &__author {
      font-size: rem-calc(20px);
      font-style: italic;
      font-weight: 300;
      color: $brand-maroon;
    }
  }
}


.nonMainPage-Hero {
  width: 100%;
  padding: rem-calc(70px 0);
  background: url("/assets/img/ada_phi_nodes_closeup.jpg");
  background-position: center bottom;
  background-size: cover;
}

.PageContent {
  padding: rem-calc(6px 0);

  h1 {
	color: $brand-maroon;
    font-size: 2.5em;
    letter-spacing: .15em;
    margin: rem-calc(20px 0);
    text-transform: uppercase;
    border-bottom: 2px solid #707373;
  }

  h2 {
    color: $brand-maroon;
    font-size: 2em;
	letter-spacing: .1rem;
    margin: rem-calc(20px 0);
    border-bottom: 1px dashed rgba(112, 115, 115, 0.5);
  }

  /*ul {
    margin-left: 3rem;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }*/

  h3 {
	font-size: 1.75em;
    letter-spacing: .065em;
  }

  h4 {
	font-size: 1.5em;
    letter-spacing: .050em;
  }

  a:not(.button):not(.Button) {
    color: $brand-maroon;
    border-bottom: 1px dotted $brand-maroon;

    &:hover,
    &:focus,
    &:active {
      border-bottom-style: solid;
    }
  }
}

.Sidebar {
  padding: 0;
  background-color: $brand-sand;
  color: $brand-gray;
  /*font-weight: 600;*/

  h1 {
    font-size: rem-calc(30px);
	letter-spacing: .075em;
    padding: rem-calc(0 0 10px 0);
    margin: rem-calc(10px 0);
  }
  h2 {
	letter-spacing: .05em;
  }

  &__widget {
    margin: rem-calc(0 0 16px 0);
	margin: 0;
  }

  &__widget--messages {
    h1 {
      border-bottom: 1px solid $brand-maroon;
    }

    ul {
      list-style: none;
      margin: 0;
    }

    a {
       /*display: block;*/
	  display: inline;
      color: #000000;
      transition: all 0.2s ease;
      padding: rem-calc(8px 0);

      &:hover,
      &:focus,
      &:active {
        color: $brand-maroon;

        &::after {
          width: 100%;
          border-color: $brand-maroon;
        }
      }

      &::after {
        content: " ";
        display: block;
        height: 0;
        width: 50%;
        transition: all 0.2s ease;
        border-bottom: 1px solid #eee;
        padding: rem-calc(8px 0);
      }
    }
  }

  &__widget--quicklinks {
    /*border-style: solid;
    border-width: 2px;
    border-color: $brand-maroon;*/
	padding: rem-calc(.2px 10px 1px 10px);

    h1 {
      border-bottom: 1px solid $brand-maroon;
      padding: 0;
      /*text-align: right;*/
    }

    ul {
      padding: 0;
      list-style: none;
      text-align:left;
    }

    a {
      /*display: block;*/
	  display: inline;
      color: #000000;
      /*transition: all 0.2s ease;
      /*padding: rem-calc(8px 0);*/

      &:hover,
      &:focus,
      &:active {
        color: $brand-maroon;
		font-weight: 600;
		border-bottom: 1px dashed $brand-gray;

        &::after {
          width: 100%;
          border-color: $brand-maroon;
        }
      }

      &::after {
        content: " ";
        display: block;
        height: 0;
        width: 50%;
        transition: all 0.2s ease;
        /*border-bottom: 1px solid #eee;
        padding: rem-calc(8px 0); */
      }
    }
  }
  &__twitter {

    .fa-twitter {
      position: relative;
      top: 4px;
      font-size: 20px;
      color: white;
      padding: rem-calc(5.4px 5.1px 6px 6px);
      background: #500000;
      border-radius:5px;
    }

    a {
      &:hover {
        opacity: 0.7;
        position: relative;
        top: 4px;
        font-size: 20px;
        color: white;
        padding: rem-calc(5.4px 5.1px 6px 6px);
        background: #500000;
        border-radius:5px;
      }
      &:focus ,
      &:active {
        opacity: 0.7;
        position: relative;
        top: 4px;
        font-size: 20px;
        color: white;
        padding: rem-calc(5.4px 5.1px 6px 6px);
        background: #500000;
        border-radius:5px;
      }
    }
  }
}

.Button {
  display: block;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.2s ease, color 0.2s ease;
  vertical-align: middle;
  border: 1px solid $brand-maroon;
  padding: rem-calc(16px);
  margin: 0 0 1rem 0;
  background-color: $brand-maroon;
  color: $brand-white;
  margin: rem-calc(0 0 16px 0);

  &:hover,
  &:focus,
  &:active {
	color: $brand-white;
    background: $brand-maroon-darker;
  }
}

.table, th, td {
    border: 1px solid black;
}

/* table customization */
table {
    border-collapse: collapse;
    thead {
        th {
            text-align: center;
        }
    }
}

/*
    image w/caption container
    adapted from http://shermandigital.com/blog/create-an-overlay-image-caption-for-foundation/
*/
.img-container {
    position: relative;
    width: 60%;
    margin: auto;
    margin-bottom: 1rem;

    .caption-image {
        width: 100%;
    }

    .caption-container {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $orbit-caption-background-color;
        color: white;
        width: 100%;
        padding: rem-calc(15px 15px 15px 15px);
    }

    .caption-content {
        padding: emCalc(10px);
        margin: 0;
    }
}

.news-event-container {
  padding-top: 0.9375em;

  @include breakpoint(large) {
    padding-left: 0em;
    padding-top: 0.6375em;
  }
}

.news-event {
    padding: 0.40em;
}
