$menu-delay: 400ms;

.oc-container,
.oc-pusher,
.oc-content {
  height: 100%;
}

.oc-content {
  verflow-y: scroll;
}

.oc-content,
.oc-content-inner {
  position: relative;
  overflow: hidden;
}

.oc-container {
  position: relative;
  overflow: hidden;
  /*overflow-y: scroll;*/
}

.oc-pusher {
  height: auto;
  position: relative;
  left: 0;
  -webkit-transition: -webkit-transform $menu-delay;
  transition: transform $menu-delay;
  z-index: 99;

  &::after {
    content: '';
    height: 0;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    width: 0;
    -webkit-transition: opacity $menu-delay, width 0.1s $menu-delay, height 0.1s $menu-delay;
    transition: opacity $menu-delay, width 0.1s $menu-delay, height 0.1s $menu-delay;
    background: transparent;
  }
}

.oc-menu {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all $menu-delay;
  transition: all $menu-delay;
  visibility: hidden;
  width: 225px;
  z-index: 100;
  background: $brand-gray;

  ul {
    background: $brand-gray;
    min-height: 100vh;
  }

  a {
    color: $brand-white;

    &:hover,
    &:focus,
    &:active {
      color: $brand-white;
      background: $brand-gray-darker;
    }
  }
}

.oc-overlay {
  display: none;
  background: #000;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.oc-menu-open {
  .oc-pusher::after {
    height: 100%;
    opacity: 1;
    -webkit-transition: opacity $menu-delay;
    transition: opacity $menu-delay;
    width: 100%;
  }

  .oc-menu::after {
    height: 0;
    opacity: 0;
    -webkit-transition: opacity $menu-delay, width 0.1s $menu-delay, height 0.1s $menu-delay;
    transition: opacity $menu-delay, width 0.1s $menu-delay, height 0.1s $menu-delay;
    width: 0;
  }

  .oc-overlay {
    display: block;
    opacity: 0.3;
  }
}

.oc-push {
  &.oc-menu {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);

    &::after {
      display: none;
    }
  }

  &.oc-menu-open {
    .oc-pusher {
      -webkit-transform: translate3d(225px, 0, 0);
      transform: translate3d(225px, 0, 0);
    }

    .oc-push.oc-menu {
      overflow: scroll;
      -webkit-transition: -webkit-transform $menu-delay;
      transition: transform $menu-delay;
      visibility: visible;
    }
  }
}

.no-js .oc-menu {
  height: auto;
  position: static;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
  visibility: hidden;
}
.no-js #oc-trigger {
  display: none;
}
.no-js .oc-pusher {
  padding-left: 0;
}

.no-csstransforms3d .oc-pusher {
  padding-left: 225px;
}
