/* === Header === */
.SiteHeader {
  .columns {
    position: relative;
  }

  &__title {
    @include flex();
    @include flex-align(left, middle);

    transition: padding 0.3s ease;
    padding-left: rem-calc(70px);
    height: rem-calc(68px);
    margin: 0;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: rem-calc(4px);
    color: $brand-maroon;
    line-height: 1.2;

    @include breakpoint(large) {
      padding-left: rem-calc(116px);
    }

    a {
      color: $brand-maroon;
    }
  }

  &__logo {
    height: auto;
    width: rem-calc(68px);
    background: $brand-maroon;
    float: left;
    transition: all 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint(large) {
      width: rem-calc(100px);
      top: rem-calc(14px);
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }

    a {
      display: block;
      padding: rem-calc(4px);

      @include breakpoint(large) {
        padding: rem-calc(8px);
      }
    }

    img[src$=".svg"] {
      width: rem-calc(60px);
      height: rem-calc(60px);
      transition: all 0.3s ease;

      @include breakpoint(large) {
        width: rem-calc(84px);
        height: rem-calc(84px);
      }
    }
  }

  &__invite {
    display: none;

    @include breakpoint(large) {
      background: $brand-sand;
      display: block;
      height: 40px;
      position: absolute;
      right: 100px;
      text-align: center;
      top: 0;
      width: 200px;
      z-index: 10;
    }

    &:after {
      display: none;

      @include breakpoint(large) {
        border-left: 100px solid transparent;
        border-right: 100px solid transparent;
        border-top: 15px solid $brand-sand;
        bottom: -15px;
        content: "";
        display: block;
        height: 0;
        left: 0;
        position: absolute;
        width: 0;
        z-index: 0;
      }
    }

    a {
      display: block;
      height: 50px;
      line-height: 45px;
      position: relative;
      width: 100%;
      z-index: 11;
    }
  }

  &__search {
    display: none;

    @include breakpoint(large) {
      display: block;
      position: absolute;
      // padding:20px;

      right: rem-calc(10px);
      top: rem-calc(-5px);
      bottom: rem-calc(-1px);
      padding: 20px;
      margin: 2px 2px;
      font-size: 16px;
      width: 50px;
      &:hover {
        opacity: 0.7;
      }
    }
    a {
      //line-height: rem-calc(46px);
      padding: rem-calc(4px);
    }
    .fa-search {
      border-radius: 5px;
      background: maroon;
      color: white;
      //border:1px solid;
      padding: 6px;
      font-size: 18px;
    }
  }

  &__youtube {
    display: none;

    @include breakpoint(large) {
      display: block;
      position: absolute;
      //right: rem-calc(118px);
      right: rem-calc(81px);
      top: rem-calc(-5px);
      padding: 20px;
      font-size: 16px;
      width: 50px;
      text-align: center;
      text-decoration: none;
      margin: 2px 2px;

      &:hover {
        opacity: 0.7;
      }
    }
    .fa-youtube-play {
      background: #bb0000;
      color: white;
      border-radius: 5px;
    }
    a {
      // line-height: rem-calc(46px);
      padding: rem-calc(7px);
    }
  }
  &__twitter {
    display: none;

    @include breakpoint(large) {
      display: block;
      position: absolute;
      //right: rem-calc(156px);
      right: rem-calc(118px);
      top: rem-calc(-5px);
      padding: 20px;
      font-size: 16px;
      width: 50px;
      text-align: center;
      text-decoration: none;
      margin: 2px 2px;

      &:hover {
        opacity: 0.7;
      }
    }
    .fa-twitter {
      background: #55acee;
      color: white;
      border-radius: 5px;
    }
    a {
      //line-height: rem-calc(38px);
      padding: rem-calc(7px);
    }
  }
  &__kb {
    display: none;

    @include breakpoint(large) {
      display: block;
      position: absolute;
      right: rem-calc(81px);
      top: rem-calc(-5px);
      padding: 20px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      width: 50px;
      margin: 2px 2px;

      &:hover {
        opacity: 0.7;
      }
    }
    a {
      padding: rem-calc(7px);
    }

    .fa-info {
      border-radius: 5px;
      //border: 1px grey;
      // border-style: solid;
      color: white;
      background: #003C71;
      font-weight: 600;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  &__menu__divider {
    border-right: 1px solid $brand-maroon;
  }
&__wiki {
  display: none;

  @include breakpoint(large) {
    display: block;
    position: absolute;
    right: rem-calc(43px);
    top: rem-calc(-5px);
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    width: 50px;
    margin: 2px 2px;

    &:hover {
      opacity: 0.7;
    }
  }
  a {
    padding: rem-calc(7px);
  }

  .fa-wikipedia-w {
    border-radius: 5px;
    //border: 1px grey;
    // border-style: solid;
    color: white;
    background: black;
    font-weight: 600;
    padding-left: 4px;
    padding-right: 4px;
  }
}
&__menu__divider {
  border-right: 1px solid $brand-maroon;
}
}

/* === Mobile Title === */
@media only screen and (max-width: 63.9375em) {
  .SiteHeader {
    &__title a {
      font-size: 1.5rem;
      letter-spacing: rem-calc(2px);
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: 500px) {
  .SiteHeader {
    &__title a {
      font-size: 1rem;
    }
  }
}

/* === Nav === */
.SiteNav {
  background: $brand-white;
  border: 1px solid $brand-maroon;
  border-width: 1px 0;

  @include breakpoint(large) {
    background: $brand-maroon;
  }

  .menu > li > a {
    @include flex();
    @include flex-align(left, middle);
  }

  .top-bar-left {
    flex: 0 0 auto;
    width: auto;
  }

  .top-bar-right {
    flex: 0 1 auto;
    width: auto;
  }

  &__menu {
    @include breakpoint(large) {
      padding-left: rem-calc(116px);
    }

    .menu-btn {
      text-transform: uppercase;

      span {
        margin-left: rem-calc(8px);
      }
    }

    a {
      @include flex();
      @include flex-align(center, middle);
      height: rem-calc(60px);
      padding: rem-calc(16px);
    }

    > ul > a {
      font-size: rem-calc(22px);
      font-weight: 600;
    }

    > li > a {
      color: $brand-maroon;
      font-size: rem-calc(18px);
      font-weight: lighter;

      @include breakpoint(large) {
        color: $brand-white;
        background: $brand-maroon;
      }

      &:hover,
      &:active,
      &:focus {
        color: $brand-white;
        background: $brand-maroon;

        @include breakpoint(large) {
          background: $brand-maroon-darker;
        }
      }
    }

    .submenu a {
      color: $brand-white;
      background: $brand-gray;

      &:hover,
      &:active,
      &:focus {
        background: $brand-gray-darker;
      }
    }
  }

  &__menu--mobile {
    @include breakpoint(large) {
      display: none;
    }
  }

  &__menu--desktop {
    display: none;
    @include breakpoint(large) {
      display: flex;
    }
  }

  &__menu--no-buttons {
    > li > a {
      color: $brand-maroon;
      background: none;
      padding: rem-calc(0 8px);
      font-weight: 400;

      &:hover,
      &:active,
      &:focus {
        background: none;
        color: $brand-maroon;
      }
    }
  }

  &__menu__dropdown {
    border: 0;
  }

  &__menu__divider {
    border-right: 1px solid $brand-maroon;
  }
}

/* Offcanvas */
.off-canvas-wrapper,
.off-canvas-content,
#offCanvas {
  min-height: 100vh;
}

#offCanvas {
  padding-top: rem-calc(45px);

  a {
    display: block;
    width: 100%;
    color: $brand-white;
    padding: rem-calc(16px);

    &:hover,
    &:active,
    &:focus {
      background: $brand-gray-darker;
    }
  }
}

.Search {
  display: none;
  background: $brand-maroon-darker;
  padding: rem-calc(20px 0);
  position: absolute;
  width: 100%;
  z-index: 10;

  .input-group {
    margin: 0;
  }

  .input-group-field {
    border-radius: 3px 0 0 3px;
  }

  .input-group-button input {
    border-radius: 0 3px 3px 0;
    background: $brand-light-gray;
    padding: 0.8em 1em;
  }
}

.invite-link {
  color: $brand-maroon;
  font-family: $brand-georgia;
  font-weight: 400;
  font-style: italic;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: $brand-maroon;
  }
}

.search-link {
  color: $brand-maroon;
  // font-size: rem-calc(22px);

  &:hover,
  &:active,
  &:focus {
    color: $brand-maroon;
  }
}
