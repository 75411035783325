/* Override original orbit caption:
	* Maroon background
 */
.orbit-caption {
    background-color: $orbit-caption-background-color;
    color: white;
    /* padding: rem-calc(50px 50px 50px 50px); */
    font-size: 1rem; 
}

/* class for left orbit caption */
.orbit-caption-left {
    top: 0;
    margin-top:0;
    width: 30%;
	max-height: 100%;
}

@media only screen and (max-width: 63.9375em) {
	.orbit-caption {
		display: none;
	}
}

/* Override original orbit buttons:
	* Add background color
	* Change on-hover color
 */
.orbit-previous, .orbit-next {
  background-color: $orbit-control-background-hover;
  padding: 0.75rem;

  &:hover,
  &:active,
  &:focus {
    background-color: $orbit-control-background-hover;
  }
}

.breadcrumbs-container {
	background-color: $breadcrumbs-background-color;
}
.breadcrumbs a{
	color: $brand-maroon;
}

/* no table border for google search results */
.searchresults {
    .table, th, td {
        border: 0px solid black;
    }
}

/* make pull down menus appear over search bar if opened */
.is-dropdown-submenu {
    z-index: 11;
}

.button {
    background-color: $brand-maroon;
  &:hover,
  &:focus,
  &:active {
	color: $brand-white;
    background: $brand-maroon-darker;
  }
}

.tabs-title a {
    font-size: 1.0rem;
}
