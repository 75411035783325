@font-face {
    font-family: 'TungstenSemibold';
    src: url('../../assets/fonts/TungstenSemibold.eot');
    src: url('../../assets/fonts/TungstenSemibold.eot') format('embedded-opentype'),
         url('../../assets/fonts/TungstenSemibold.woff2') format('woff2'),
         url('../../assets/fonts/TungstenSemibold.woff') format('woff'),
         url('../../assets/fonts/TungstenSemibold.ttf') format('truetype'),
         url('../../assets/fonts/TungstenSemibold.svg#TungstenSemibold') format('svg');
}

@font-face {
    font-family: 'TungstenNarrowMedium';
    src: url('../../assets/fonts/TungstenNarrow-Medium.eot');
    src: url('../../assets/fonts/TungstenNarrow-Medium.eot') format('embedded-opentype'),
         url('../../assets/fonts/TungstenNarrow-Medium.woff2') format('woff2'),
         url('../../assets/fonts/TungstenNarrow-Medium.woff') format('woff'),
         url('../../assets/fonts/TungstenNarrow-Medium.ttf') format('truetype'),
         url('../../assets/fonts/TungstenNarrow-Medium.svg#TungstenNarrow-Medium') format('svg');
}

@font-face {
    font-family: 'TungstenNarrowSemibold';
    src: url('../../assets/fonts/TungstenNarrow-Semibold.eot');
    src: url('../../assets/fonts/TungstenNarrow-Semibold.eot') format('embedded-opentype'),
         url('../../assets/fonts/TungstenNarrow-Semibold.woff2') format('woff2'),
         url('../../assets/fonts/TungstenNarrow-Semibold.woff') format('woff'),
         url('../../assets/fonts/TungstenNarrow-Semibold.ttf') format('truetype'),
         url('../../assets/fonts/TungstenNarrow-Semibold.svg#TungstenNarrow-Semibold') format('svg');
}

@font-face {
    font-family: 'MoristonRegular';
    src: url('../fonts/MoristonRegular.eot');
    src: url('../fonts/MoristonRegular.eot') format('embedded-opentype'),
         url('../fonts/MoristonRegular.woff2') format('woff2'),
         url('../fonts/MoristonRegular.woff') format('woff'),
         url('../fonts/MoristonRegular.ttf') format('truetype'),
         url('../fonts/MoristonRegular.svg#MoristonRegular') format('svg');
}

$brand-open-sans: 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
/*$brand-tungsten: 'Tungsten A', 'Tungsten B', sans-serif;*/
$brand-tungsten: TungstenNarrowSemibold, Arial;
$brand-moriston: 'MoristonRegular', sans-serif;
$brand-minion-pro: 'Minion Pro', serif;

// Not actually in brand guide; for use when web fonts not available or on legacy sites
$brand-georgia: Georgia, Times, 'Times New Roman', serif;
