@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'a11y-offcanvas';
@import 'font-awesome';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-card;
// @include foundation-close-button;
@include foundation-menu;
// @include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
@include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
// @include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'header';
@import 'content';
@import 'footer';
@import 'components/customize';

html {
  height: 100% !important;
}

body {
  min-height: 100% !important;
}

.SkipNav {
  a {
    background: $brand-maroon;
    border-radius: 0 0 3px 3px;
    color: #fff;
    height: 25px;
    left: 50%;
    margin-left: -100px;
    padding-left: 10px;
    position: absolute;
    top: -25px;
    transition: top 0.2s ease;
    width: intrinsic;           /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content;    /* Firefox/Gecko */
    width: -webkit-max-content; /* Chrome */
    z-index: 9999;

    &:focus {
      top: 0;
    }
  }
}
